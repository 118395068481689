import { Location } from "@angular/common";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";
import { PORTAL_STYLES } from "@Unit/shared/Type/config/portal-style.config";
import { PortalStyle } from "@Unit/shared/Type/Interfaces/portal-style.interface";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit, OnDestroy {
  public currentYear: number;
  public portalStyleConfig: PortalStyle = PORTAL_STYLES.universal;
  public isPortalStyleChecked: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  public displayFooter: boolean = false;
  private isUnitPortalStyle: boolean = false;
  protected currentUrl: string = "";

  constructor(
    private readonly location: Location,
    private readonly unitSharedService: UnitSharedService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.checkPortalStyleBasedOnPath();
    this.activateFooter();
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.checkPortalStyleBasedOnPath();
      }
    });
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }

  private activateFooter(): void {
    const pathname = window.location.pathname.toLowerCase();
    const isRedirect = pathname === "/redirect";
    const portalAction = localStorage.getItem("portalAction");
    if (isRedirect && portalAction) {
      setTimeout(() => {
        this.displayFooter = true;
      }, 3000);
    } else {
      this.displayFooter = true;
    }
  }

  private checkPortalStyleBasedOnPath(): void {
    const path = this.location.path();
    this.portalStyleConfig = { ...PORTAL_STYLES.universal };
    if (path.includes("/empresas")) {
      this.isPortalStyleChecked = false;
    } else if (this.isUnitPortalStyle) {
      this.portalStyleConfig = { ...PORTAL_STYLES.unit };
      this.isPortalStyleChecked = true;
    } else if (path === "/portales" || path.includes("/contactanos")) {
      this.checkPortalStyle();
    } else {
      this.isPortalStyleChecked = true;
    }
  }

  private checkPortalStyle(): void {
    const portalStyleFromSession = window.localStorage.getItem("PortalStyle");

    if (portalStyleFromSession) {
      this.portalStyleConfig = PORTAL_STYLES[portalStyleFromSession.toLowerCase()] || PORTAL_STYLES.universal;
    } else {
      this.portalStyleConfig = PORTAL_STYLES.universal;
    }

    this.isPortalStyleChecked = true;
  }

  public getTermsOfUseLink(): string {
    this.checkPortalStyleBasedOnPath();
    return this.portalStyleConfig.portalStyle
      ? `/aviso-legal/terminos-uso/${this.portalStyleConfig.portalStyle.toLowerCase()}`
      : "/aviso-legal/terminos-uso";
  }

  public getPrivacyPolicyLink(): string {
    return this.portalStyleConfig.portalStyle
      ? `/aviso-legal/politicas-privacidad/${this.portalStyleConfig.portalStyle.toLowerCase()}`
      : "/aviso-legal/politicas-privacidad";
  }
  public getFooterByRoute(): string {
    if (this.currentUrl.includes("onboarding")) {
      return "FOOTER_ONBOARDING";
    }
    return "FOOTER_DEFAULT";
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
