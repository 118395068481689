import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { UnitSharedRepository } from "@Unit/shared/repositories/unitshared.repository";
import { SharedUnitModule } from "@Unit/shared/shared-unit.module";

import { AutoAssuranceLawInfoComponent } from "./modals/auto-assurance-law-info/auto-assurance-law-info.component";
import { UnitRoutingModule } from "./unit-routing.module";

@NgModule({
  declarations: [AutoAssuranceLawInfoComponent],
  imports: [SharedUnitModule, UnitRoutingModule, ReactiveFormsModule],

  providers: [UnitSharedRepository],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UnitModule {}
