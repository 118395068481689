<!-- Footer -->
<footer class="text-white">
  <section class="section_links pt-4 pt-lg-5">
    <div class="container-fluid text-start">
      <!-- Grid row -->
      <div class="row d-none d-lg-flex">
        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <svg
            class="d-block mx-auto"
            width="205"
            height="57"
            viewBox="0 0 205 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.1801 9.87987V7.12891H84.3464H84.0425H81.3945V26.5617H83.934H84.3464H93.1801V23.8108H84.3464V17.6926H92.0515V14.9637H84.3464V9.87987H93.1801Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M125.367 23.8104H116.555V7.0625H113.625V23.5683C113.625 24.4046 113.885 25.1088 114.406 25.703C114.927 26.2973 115.644 26.5834 116.555 26.5834H125.367C126.235 26.5834 126.973 26.2973 127.516 25.725C128.059 25.1528 128.319 24.4266 128.319 23.5683V7.0625H125.345V23.8324L125.367 23.8104Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M145.899 26.5617H142.513L134.851 17.6926H134.135V26.5617H131.205V7.12891H142.47C143.295 7.12891 143.989 7.43701 144.553 8.03122C145.118 8.60342 145.422 9.30767 145.422 10.144V14.7216C145.422 16.7243 144.445 17.7366 142.491 17.7366H138.368L145.943 26.6057L145.899 26.5617ZM142.448 14.9637V9.87987H134.113V14.9637H142.448Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M78.5496 23.5687C78.5496 24.427 78.2891 25.1312 77.7899 25.6814C77.2473 26.2756 76.531 26.5837 75.6194 26.5837H65.0927V23.8328H75.6194V17.7146H67.7841C67.0461 17.7146 66.4167 17.5166 65.9175 17.1204C65.4183 16.7243 65.071 16.1961 64.9191 15.5359C64.854 15.2718 64.8105 15.0077 64.8105 14.7216V10.144C64.8105 9.35169 65.0927 8.66945 65.657 8.05323C66.2213 7.43701 66.9159 7.12891 67.7841 7.12891H77.5294V9.87987H67.7624V14.9637H75.6194C76.4659 14.9637 77.1605 15.2498 77.7248 15.844C78.2891 16.4162 78.5713 17.1424 78.5713 17.9787V23.5687H78.5496Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M179.563 23.5687C179.563 24.427 179.303 25.1312 178.804 25.6814C178.261 26.2756 177.545 26.5837 176.633 26.5837H166.106V23.8328H176.633V17.7146H168.798C168.06 17.7146 167.43 17.5166 166.931 17.1204C166.432 16.7243 166.106 16.1961 165.933 15.5359C165.868 15.2718 165.824 15.0077 165.824 14.7216V10.144C165.824 9.35169 166.106 8.66945 166.649 8.05323C167.213 7.43701 167.908 7.12891 168.776 7.12891H178.521V9.87987H168.754V14.9637H176.611C177.458 14.9637 178.152 15.2498 178.717 15.844C179.281 16.4162 179.563 17.1424 179.563 17.9787V23.5687Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M162.981 23.5452C162.981 25.5479 162.005 26.5603 160.051 26.5603H151.217C149.655 26.5603 148.7 25.9001 148.374 24.5576C148.309 24.2495 148.266 23.9194 148.266 23.5452V10.1205C148.266 9.32825 148.548 8.64601 149.112 8.02979C149.676 7.41358 150.371 7.10547 151.217 7.10547H160.051C160.876 7.10547 161.571 7.41358 162.135 8.00779C162.721 8.57999 163.003 9.28423 163.003 10.1205V23.5452H162.981ZM160.03 23.8093V9.85643H151.196V23.8093H160.03Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M110.761 26.5628H98.9753C97.4125 26.5628 96.4575 25.9025 96.132 24.5601C96.0668 24.252 96.0234 23.9218 96.0234 23.5477V10.101C96.0234 9.30872 96.3056 8.62648 96.8699 8.01026C97.4342 7.39405 98.1288 7.08594 98.9753 7.08594H110.283V9.8149H98.9753V23.8118H107.809V17.6717H101.927V14.9427H110.739V26.5408L110.761 26.5628Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M97.6919 50.024H95.5648L85.7977 36.4672V50.024H82.8242V30.3711H84.9296L94.7183 43.9278V30.3711H97.6919V50.024Z"
              fill="white"
            />
            <path d="M103.552 30.3711H100.578V50.002H103.552V30.3711Z" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M120.266 30.3711L114.319 50.024H111.324L105.398 30.3711H108.242L112.843 45.5784L117.423 30.3711H120.266Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M151.368 50.0254H147.938L140.19 41.0683H139.474V50.0254H136.5V30.3945H147.873C148.72 30.3945 149.414 30.7026 149.979 31.2968C150.565 31.8911 150.847 32.5953 150.847 33.4316V38.0532C150.847 40.0779 149.848 41.0903 147.873 41.0903H143.706L151.346 50.0474L151.368 50.0254ZM147.895 38.2953V33.1455H139.474V38.2953H147.895Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M167.646 46.9884C167.646 47.8467 167.386 48.5729 166.887 49.1231C166.344 49.7393 165.606 50.0254 164.695 50.0254H154.059V47.2524H164.695V41.0683H156.794C156.034 41.0683 155.405 40.8702 154.906 40.4741C154.385 40.0779 154.059 39.5497 153.907 38.8675C153.842 38.6034 153.799 38.3393 153.799 38.0312V33.4096C153.799 32.6173 154.081 31.9131 154.645 31.3189C155.21 30.7026 155.926 30.3945 156.794 30.3945H166.648V33.1675H156.772V38.3173H164.695C165.541 38.3173 166.257 38.6034 166.822 39.1976C167.386 39.7918 167.668 40.4961 167.668 41.3544V47.0104L167.646 46.9884Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M185.379 50.0254H182.406V41.0683H173.485V50.0254H170.512V33.4316C170.512 32.5733 170.772 31.869 171.315 31.2748C171.857 30.6806 172.574 30.3945 173.485 30.3945H182.406C183.122 30.3945 183.795 30.7246 184.424 31.3849C185.054 32.0231 185.379 32.7053 185.379 33.4316V50.0254ZM182.428 38.2953V33.1455H173.507V38.2953H182.428Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M200.181 50.024H188.287V30.3711H191.239V47.251H200.181V50.024Z"
              fill="white"
            />
            <path
              d="M203.48 50.069C202.569 50.069 201.961 49.4088 201.961 48.4844C201.961 47.5601 202.59 46.9219 203.48 46.9219C204.37 46.9219 205 47.5821 205 48.5064C205 49.4308 204.37 50.069 203.48 50.069ZM203.48 47.0759C202.699 47.0759 202.135 47.6481 202.135 48.4844C202.135 49.3207 202.699 49.9149 203.48 49.9149C204.262 49.9149 204.826 49.3427 204.826 48.5064C204.826 47.6701 204.262 47.0759 203.48 47.0759ZM203.936 49.3207L203.415 48.5725H203.22V49.2987H202.981V47.5601H203.459C203.828 47.5601 204.045 47.7582 204.045 48.0663C204.045 48.3304 203.893 48.4844 203.676 48.5504L204.175 49.2767L203.914 49.3427L203.936 49.3207ZM203.459 47.8022H203.242V48.3964H203.459C203.676 48.3964 203.828 48.2864 203.828 48.0883C203.828 47.8902 203.697 47.8022 203.48 47.8022H203.459Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M133.982 33.1441V30.3711H125.061H124.758H122.088V50.024H124.649H125.061H133.982V47.251H125.061V41.0668H132.853V38.2939H125.061V33.1441H133.982Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M76.9427 47.251H68.0438V33.3862L65.0703 30.3711V46.9649C65.0703 47.8232 65.3308 48.5275 65.8734 49.1217C66.3943 49.7159 67.1323 50.002 68.0438 50.002H76.9427C77.8326 50.002 78.5489 49.7159 79.1132 49.1437C79.6558 48.5495 79.938 47.8232 79.938 46.9649V30.3711H76.9427V47.229V47.251Z"
              fill="white"
            />
            <path
              d="M48.0983 24.8262H32.6012L28.9766 28.5015H48.0983C48.272 28.5015 48.4239 28.5015 48.5758 28.4794C49.4006 28.3914 50.0951 28.0833 50.6812 27.5331C51.0719 27.181 51.354 26.7408 51.5276 26.2347C51.6145 26.0146 51.6579 25.7725 51.7013 25.5304C51.723 25.3103 51.7447 25.0903 51.7447 24.8482V13.8223C51.7447 12.7219 51.3974 11.8196 50.7246 11.1374C50.0083 10.4551 49.1401 10.125 48.0983 10.125H43.7574C43.931 10.5652 44.0179 11.0053 44.0179 11.4895C44.0179 11.5555 44.0179 11.6215 44.0179 11.6655C43.9744 12.4578 43.7574 13.1841 43.345 13.8223H48.12V24.8482L48.0983 24.8262Z"
              fill="white"
            />
            <path d="M28.7383 13.7989H36.6171L38.8092 11.5761L37.3767 10.1016H28.7383V13.7989Z" fill="white" />
            <path
              d="M39.6762 11.5761L28.7154 22.69V27.8838L42.2374 14.173C42.3459 14.0629 42.4544 13.9309 42.563 13.8209C43.0839 13.1826 43.366 12.4344 43.3877 11.6421C43.4094 11.1139 43.3009 10.6077 43.0839 10.1236C42.9753 9.90347 42.8668 9.70541 42.7149 9.50734C42.5847 9.33127 42.4327 9.15521 42.2591 8.97915L34.5757 1.18842C33.816 0.396139 32.9478 0 31.9711 0C30.9944 0.0220077 30.1479 0.418147 29.41 1.16641L26.3496 4.2695C26.762 4.46757 27.1527 4.70965 27.4782 5.06178C27.5217 5.10579 27.5651 5.14981 27.6085 5.19382C28.1294 5.76602 28.4767 6.44826 28.6286 7.21853L32.0145 3.78533L39.6979 11.5761H39.6762Z"
              fill="white"
            />
            <path d="M23.8758 12.0182V8.87109H21.8355L15.7148 15.0553L18.2977 17.6742L23.8758 12.0182Z" fill="white" />
            <path d="M13.631 27.882V19.8932L11.4171 17.6484L9.96289 19.123V27.882H13.631Z" fill="white" />
            <path
              d="M24.4833 8.22855V23.9421L28.108 27.6174V8.22855C28.108 8.05249 28.108 7.89843 28.0863 7.74438C27.9994 6.90808 27.6956 6.20384 27.153 5.60963C26.8057 5.21349 26.3716 4.92739 25.8724 4.75133C25.6553 4.6633 25.4166 4.61928 25.1778 4.57527C24.9608 4.55326 24.7438 4.53125 24.505 4.53125H13.631C12.5458 4.53125 11.6559 4.88337 10.983 5.56561C10.3102 6.29187 9.98462 7.17218 9.98462 8.22855V12.6301C10.4187 12.454 10.8528 12.366 11.3303 12.366C11.3954 12.366 11.4605 12.366 11.5039 12.366C12.2853 12.41 13.0016 12.6301 13.631 13.0482V8.20654H24.505L24.4833 8.22855Z"
              fill="white"
            />
            <path
              d="M28.1076 29.3813V48.7701C28.1076 48.9461 28.1076 49.1002 28.1293 49.2543C28.2162 50.0905 28.52 50.7948 29.0626 51.389C29.4099 51.7851 29.844 52.0712 30.3432 52.2473C30.5603 52.3353 30.799 52.3794 31.0378 52.4234C31.2548 52.4454 31.4719 52.4674 31.7106 52.4674H42.5846C43.6698 52.4674 44.5597 52.1153 45.2326 51.433C45.9054 50.7068 46.231 49.8265 46.231 48.7701V29.1172H42.5846V48.7701H31.7106V33.0566L28.0859 29.3813H28.1076Z"
              fill="white"
            />
            <path
              d="M11.4166 16.77L22.3775 27.8839H27.4997L13.9778 14.1731C13.8692 14.063 13.739 13.953 13.6305 13.843C13.0011 13.3148 12.2631 13.0287 11.46 13.0067C10.9391 12.9847 10.4399 13.0947 9.96241 13.3148C9.74537 13.4248 9.55003 13.5349 9.35469 13.6889C9.18105 13.821 9.00741 13.975 8.83378 14.1511L1.15034 21.9418C0.390683 22.7121 0 23.5924 0 24.5827C0.0217046 25.5731 0.412387 26.4314 1.15034 27.1796L4.21069 30.2827C4.40604 29.8646 4.64479 29.4684 4.99206 29.1383C5.03547 29.0943 5.07888 29.0503 5.12229 29.0063C5.68661 28.4781 6.35945 28.126 7.11911 27.9719L3.73319 24.5387L11.4166 16.748V16.77Z"
              fill="white"
            />
            <path d="M17.4279 38.445L11.8499 32.7891H8.74609V34.8798L14.8451 41.064L17.4279 38.445Z" fill="white" />
            <path
              d="M8.11903 32.1753H23.6161L27.2408 28.5H8.11903C7.94539 28.5 7.79346 28.5 7.64153 28.522C6.81675 28.61 6.12221 28.9181 5.53618 29.4683C5.1455 29.8205 4.86334 30.2606 4.6897 30.7668C4.60288 30.9869 4.55947 31.229 4.51607 31.471C4.49436 31.6911 4.47266 31.9112 4.47266 32.1533V43.1792C4.47266 44.2795 4.81993 45.1819 5.49277 45.8641C6.20902 46.5463 7.07721 46.8764 8.11903 46.8764H12.46C12.2863 46.4363 12.1995 45.9961 12.1995 45.512C12.1995 45.4459 12.1995 45.3799 12.1995 45.3359C12.2429 44.5436 12.46 43.8174 12.8723 43.1792H8.09733V32.1533L8.11903 32.1753Z"
              fill="white"
            />
            <path d="M27.3489 43.1992H19.6221L17.4082 45.422L18.8624 46.8965H27.3489V43.1992Z" fill="white" />
            <path
              d="M16.5384 45.4249L27.4993 34.311V29.1172L13.9773 42.828C13.8688 42.938 13.7602 43.0701 13.6517 43.1801C13.1308 43.8183 12.8486 44.5666 12.8269 45.3809C12.8052 45.9091 12.9138 46.4153 13.1308 46.8994C13.2393 47.1195 13.3479 47.3176 13.4998 47.5156C13.63 47.6917 13.7819 47.8678 13.9556 48.0438L21.639 55.8346C22.3987 56.6048 23.2669 57.001 24.2436 57.001C25.2203 56.979 26.0668 56.5828 26.8047 55.8346L29.8651 52.7315C29.4527 52.5334 29.062 52.2913 28.7364 51.9392C28.693 51.8952 28.6496 51.8512 28.6062 51.8072C28.0853 51.235 27.738 50.5527 27.5861 49.7824L24.2002 53.2156L16.5167 45.4249H16.5384Z"
              fill="white"
            />
            <path d="M37.9158 39.3516L32.3594 44.9855V48.1547H34.3996L40.4986 41.9705L37.9158 39.3516Z" fill="white" />
            <path d="M28.7383 29.1172H33.8606L41.9998 37.3701V42.5859L28.7383 29.1172Z" fill="white" />
            <path d="M38.7872 18.5524L44.3653 24.2084H47.4908V22.1176L41.3701 15.9335L38.7872 18.5524Z" fill="white" />
            <path
              d="M55.0889 29.8218L52.0285 26.7188C51.8332 27.1369 51.5944 27.533 51.2472 27.8632C51.2038 27.9072 51.1603 27.9512 51.1169 27.9952C50.5526 28.5234 49.8798 28.8755 49.1201 29.0296L52.506 32.4628L46.9062 38.1408V43.3126C47.0799 43.1805 47.2535 43.0485 47.4055 42.8724L55.0889 35.0817C55.8485 34.3114 56.2392 33.4311 56.2392 32.4408C56.2175 31.4504 55.8268 30.5921 55.0889 29.8438V29.8218Z"
              fill="white"
            />
          </svg>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="txt_group_link">Sobre mi plan</h6>
          <p class="txt_link">
            <a class="text-reset">Coberturas</a>
          </p>
          <p class="txt_link">
            <a class="text-reset">Mis Dependientes</a>
          </p>
          <p class="txt_link">
            <a class="text-reset">Contrato</a>
          </p>
          <p class="txt_link">
            <a class="text-reset">Otros productos</a>
          </p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="txt_group_link">Facturación</h6>
          <p class="txt_link">
            <a class="text-reset">Facturas de Servicios</a>
          </p>
          <p class="txt_link">
            <a class="text-reset">Recibos de Pago</a>
          </p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="txt_group_link">Documentos</h6>
          <p class="txt_link">
            <a href="#!" class="text-reset">Condiciones Particulares</a>
          </p>
          <p class="txt_link">
            <a href="#!" class="text-reset">SuperIntendencia de Seguros(SIS)</a>
          </p>
        </div>

        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="txt_group_link">Contactanos</h6>
          <p class="txt_link">
            <i-feather class="mr-1" name="mail" _nghost-yeo-c4="" ng-reflect-name="mail">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-mail">
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>
            </i-feather>
            clientes&#64;universal.com.do
          </p>
          <p class="txt_link">
            <i-feather class="mr-1" name="map-pin" _nghost-yeo-c4="" ng-reflect-name="map-pin">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-map-pin">
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>
            </i-feather>

            OFICINA CORPORATIVA

            <span class="d-block mx-auto" style="font-weight: 300; line-height: 16.94px; width: 82%">
              Av. Winston Churchill 1100, Evaristo Morales, Santo Domingo, D.N.
            </span>
          </p>
        </div>
      </div>
      <!-- Grid row -->

      <div class="accordion p-3 d-lg-none" id="accordionExample">
        <svg class="mb-4" width="205" height="57" viewBox="0 0 205 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M93.1801 9.88085V7.12988H84.3464H84.0425H81.3945V26.5627H83.934H84.3464H93.1801V23.8117H84.3464V17.6936H92.0515V14.9646H84.3464V9.88085H93.1801Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M125.367 23.8123H116.555V7.06445H113.625V23.5702C113.625 24.4065 113.885 25.1108 114.406 25.705C114.927 26.2992 115.644 26.5853 116.555 26.5853H125.367C126.235 26.5853 126.973 26.2992 127.516 25.727C128.059 25.1548 128.319 24.4285 128.319 23.5702V7.06445H125.345V23.8343L125.367 23.8123Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M145.899 26.5627H142.513L134.851 17.6936H134.135V26.5627H131.205V7.12988H142.47C143.295 7.12988 143.989 7.43799 144.553 8.0322C145.118 8.6044 145.422 9.30865 145.422 10.1449V14.7225C145.422 16.7253 144.445 17.7376 142.491 17.7376H138.368L145.943 26.6067L145.899 26.5627ZM142.448 14.9646V9.88085H134.113V14.9646H142.448Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.5496 23.5697C78.5496 24.428 78.2891 25.1322 77.7899 25.6824C77.2473 26.2766 76.531 26.5847 75.6194 26.5847H65.0927V23.8337H75.6194V17.7156H67.7841C67.0461 17.7156 66.4167 17.5175 65.9175 17.1214C65.4183 16.7253 65.071 16.1971 64.9191 15.5368C64.854 15.2727 64.8105 15.0086 64.8105 14.7225V10.1449C64.8105 9.35266 65.0927 8.67042 65.657 8.05421C66.2213 7.43799 66.9159 7.12988 67.7841 7.12988H77.5294V9.88085H67.7624V14.9646H75.6194C76.4659 14.9646 77.1605 15.2507 77.7248 15.8449C78.2891 16.4171 78.5713 17.1434 78.5713 17.9797V23.5697H78.5496Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M179.563 23.5697C179.563 24.428 179.303 25.1322 178.804 25.6824C178.261 26.2766 177.545 26.5847 176.633 26.5847H166.106V23.8337H176.633V17.7156H168.798C168.06 17.7156 167.43 17.5175 166.931 17.1214C166.432 16.7253 166.106 16.1971 165.933 15.5368C165.868 15.2727 165.824 15.0086 165.824 14.7225V10.1449C165.824 9.35266 166.106 8.67042 166.649 8.05421C167.213 7.43799 167.908 7.12988 168.776 7.12988H178.521V9.88085H168.754V14.9646H176.611C177.458 14.9646 178.152 15.2507 178.717 15.8449C179.281 16.4171 179.563 17.1434 179.563 17.9797V23.5697Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M162.981 23.5482C162.981 25.5509 162.005 26.5632 160.051 26.5632H151.217C149.655 26.5632 148.7 25.903 148.374 24.5605C148.309 24.2524 148.266 23.9223 148.266 23.5482V10.1235C148.266 9.33118 148.548 8.64894 149.112 8.03272C149.676 7.41651 150.371 7.1084 151.217 7.1084H160.051C160.876 7.1084 161.571 7.41651 162.135 8.01072C162.721 8.58292 163.003 9.28716 163.003 10.1235V23.5482H162.981ZM160.03 23.8123V9.85936H151.196V23.8123H160.03Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M110.761 26.5637H98.9753C97.4125 26.5637 96.4575 25.9035 96.132 24.561C96.0668 24.2529 96.0234 23.9228 96.0234 23.5487V10.102C96.0234 9.30969 96.3056 8.62745 96.8699 8.01124C97.4342 7.39502 98.1288 7.08691 98.9753 7.08691H110.283V9.81587H98.9753V23.8128H107.809V17.6726H101.927V14.9437H110.739V26.5417L110.761 26.5637Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M97.6919 50.024H95.5648L85.7977 36.4672V50.024H82.8242V30.3711H84.9296L94.7183 43.9278V30.3711H97.6919V50.024Z"
            fill="white"
          />
          <path d="M103.552 30.3711H100.578V50.002H103.552V30.3711Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M120.266 30.3711L114.319 50.024H111.324L105.398 30.3711H108.242L112.843 45.5784L117.423 30.3711H120.266Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M151.368 50.0244H147.938L140.19 41.0673H139.474V50.0244H136.5V30.3936H147.873C148.72 30.3936 149.414 30.7017 149.979 31.2959C150.565 31.8901 150.847 32.5943 150.847 33.4306V38.0522C150.847 40.077 149.848 41.0893 147.873 41.0893H143.706L151.346 50.0464L151.368 50.0244ZM147.895 38.2943V33.1445H139.474V38.2943H147.895Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M167.646 46.9874C167.646 47.8457 167.386 48.5719 166.887 49.1221C166.344 49.7383 165.606 50.0244 164.695 50.0244H154.059V47.2515H164.695V41.0673H156.794C156.034 41.0673 155.405 40.8692 154.906 40.4731C154.385 40.077 154.059 39.5488 153.907 38.8665C153.842 38.6024 153.799 38.3383 153.799 38.0302V33.4086C153.799 32.6163 154.081 31.9121 154.645 31.3179C155.21 30.7017 155.926 30.3936 156.794 30.3936H166.648V33.1665H156.772V38.3163H164.695C165.541 38.3163 166.257 38.6024 166.822 39.1966C167.386 39.7909 167.668 40.4951 167.668 41.3534V47.0094L167.646 46.9874Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M185.379 50.0244H182.406V41.0673H173.485V50.0244H170.512V33.4306C170.512 32.5723 170.772 31.8681 171.315 31.2739C171.857 30.6797 172.574 30.3936 173.485 30.3936H182.406C183.122 30.3936 183.795 30.7237 184.424 31.3839C185.054 32.0221 185.379 32.7044 185.379 33.4306V50.0244ZM182.428 38.2943V33.1445H173.507V38.2943H182.428Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M200.181 50.024H188.287V30.3711H191.239V47.251H200.181V50.024Z"
            fill="white"
          />
          <path
            d="M203.48 50.0682C202.568 50.0682 201.961 49.408 201.961 48.4837C201.961 47.5594 202.59 46.9211 203.48 46.9211C204.37 46.9211 204.999 47.5814 204.999 48.5057C204.999 49.43 204.37 50.0682 203.48 50.0682ZM203.48 47.0752C202.699 47.0752 202.134 47.6474 202.134 48.4837C202.134 49.32 202.699 49.9142 203.48 49.9142C204.261 49.9142 204.826 49.342 204.826 48.5057C204.826 47.6694 204.261 47.0752 203.48 47.0752ZM203.936 49.32L203.415 48.5717H203.22V49.298H202.981V47.5594H203.458C203.827 47.5594 204.044 47.7574 204.044 48.0655C204.044 48.3296 203.892 48.4837 203.675 48.5497L204.175 49.276L203.914 49.342L203.936 49.32ZM203.458 47.8015H203.241V48.3957H203.458C203.675 48.3957 203.827 48.2856 203.827 48.0876C203.827 47.8895 203.697 47.8015 203.48 47.8015H203.458Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M133.982 33.1441V30.3711H125.061H124.758H122.088V50.024H124.649H125.061H133.982V47.251H125.061V41.0668H132.853V38.2939H125.061V33.1441H133.982Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M76.9427 47.251H68.0438V33.3862L65.0703 30.3711V46.9649C65.0703 47.8232 65.3308 48.5275 65.8734 49.1217C66.3943 49.7159 67.1323 50.002 68.0438 50.002H76.9427C77.8326 50.002 78.5489 49.7159 79.1132 49.1437C79.6558 48.5495 79.938 47.8232 79.938 46.9649V30.3711H76.9427V47.229V47.251Z"
            fill="white"
          />
          <path
            d="M48.0983 24.8252H32.6012L28.9766 28.5005H48.0983C48.272 28.5005 48.4239 28.5005 48.5758 28.4785C49.4006 28.3904 50.0951 28.0823 50.6812 27.5321C51.0719 27.18 51.354 26.7399 51.5276 26.2337C51.6145 26.0136 51.6579 25.7715 51.7013 25.5294C51.723 25.3094 51.7447 25.0893 51.7447 24.8472V13.8213C51.7447 12.7209 51.3974 11.8186 50.7246 11.1364C50.0083 10.4541 49.1401 10.124 48.0983 10.124H43.7574C43.931 10.5642 44.0179 11.0043 44.0179 11.4885C44.0179 11.5545 44.0179 11.6205 44.0179 11.6646C43.9744 12.4568 43.7574 13.1831 43.345 13.8213H48.12V24.8472L48.0983 24.8252Z"
            fill="white"
          />
          <path d="M28.7383 13.7989H36.6171L38.8092 11.5761L37.3767 10.1016H28.7383V13.7989Z" fill="white" />
          <path
            d="M39.6762 11.5761L28.7154 22.69V27.8838L42.2374 14.173C42.3459 14.0629 42.4544 13.9309 42.563 13.8209C43.0839 13.1826 43.366 12.4344 43.3877 11.6421C43.4094 11.1139 43.3009 10.6077 43.0839 10.1236C42.9753 9.90347 42.8668 9.70541 42.7149 9.50734C42.5847 9.33127 42.4327 9.15521 42.2591 8.97915L34.5757 1.18842C33.816 0.396139 32.9478 0 31.9711 0C30.9944 0.0220077 30.1479 0.418147 29.41 1.16641L26.3496 4.2695C26.762 4.46757 27.1527 4.70965 27.4782 5.06178C27.5217 5.10579 27.5651 5.14981 27.6085 5.19382C28.1294 5.76602 28.4767 6.44826 28.6286 7.21853L32.0145 3.78533L39.6979 11.5761H39.6762Z"
            fill="white"
          />
          <path d="M23.8758 12.0164V8.86926H21.8355L15.7148 15.0534L18.2977 17.6724L23.8758 12.0164Z" fill="white" />
          <path d="M13.631 27.8831V19.8943L11.4171 17.6495L9.96289 19.1241V27.8831H13.631Z" fill="white" />
          <path
            d="M24.4833 8.2305V23.944L28.108 27.6193V8.2305C28.108 8.05444 28.108 7.90038 28.0863 7.74633C27.9994 6.91004 27.6956 6.20579 27.153 5.61158C26.8057 5.21544 26.3716 4.92934 25.8724 4.75328C25.6553 4.66525 25.4166 4.62123 25.1778 4.57722C24.9608 4.55521 24.7438 4.5332 24.505 4.5332H13.631C12.5458 4.5332 11.6559 4.88533 10.983 5.56757C10.3102 6.29382 9.98462 7.17413 9.98462 8.2305V12.632C10.4187 12.456 10.8528 12.368 11.3303 12.368C11.3954 12.368 11.4605 12.368 11.5039 12.368C12.2853 12.412 13.0016 12.632 13.631 13.0502V8.20849H24.505L24.4833 8.2305Z"
            fill="white"
          />
          <path
            d="M28.1076 29.3803V48.7691C28.1076 48.9452 28.1076 49.0992 28.1293 49.2533C28.2162 50.0896 28.52 50.7938 29.0626 51.388C29.4099 51.7842 29.844 52.0703 30.3432 52.2463C30.5603 52.3344 30.799 52.3784 31.0378 52.4224C31.2548 52.4444 31.4719 52.4664 31.7106 52.4664H42.5846C43.6698 52.4664 44.5597 52.1143 45.2326 51.432C45.9054 50.7058 46.231 49.8255 46.231 48.7691V29.1162H42.5846V48.7691H31.7106V33.0556L28.0859 29.3803H28.1076Z"
            fill="white"
          />
          <path
            d="M11.4166 16.77L22.3775 27.8839H27.4997L13.9778 14.1731C13.8692 14.063 13.739 13.953 13.6305 13.843C13.0011 13.3148 12.2631 13.0287 11.46 13.0067C10.9391 12.9847 10.4399 13.0947 9.96241 13.3148C9.74537 13.4248 9.55003 13.5349 9.35469 13.6889C9.18105 13.821 9.00741 13.975 8.83378 14.1511L1.15034 21.9418C0.390683 22.7121 0 23.5924 0 24.5827C0.0217046 25.5731 0.412387 26.4314 1.15034 27.1796L4.21069 30.2827C4.40604 29.8646 4.64479 29.4684 4.99206 29.1383C5.03547 29.0943 5.07888 29.0503 5.12229 29.0063C5.68661 28.4781 6.35945 28.126 7.11911 27.9719L3.73319 24.5387L11.4166 16.748V16.77Z"
            fill="white"
          />
          <path d="M17.4279 38.447L11.8499 32.791H8.74609V34.8818L14.8451 41.0659L17.4279 38.447Z" fill="white" />
          <path
            d="M8.11903 32.1753H23.6161L27.2408 28.5H8.11903C7.94539 28.5 7.79346 28.5 7.64153 28.522C6.81675 28.61 6.12221 28.9181 5.53618 29.4683C5.1455 29.8205 4.86334 30.2606 4.6897 30.7668C4.60288 30.9869 4.55947 31.229 4.51607 31.471C4.49436 31.6911 4.47266 31.9112 4.47266 32.1533V43.1792C4.47266 44.2795 4.81993 45.1819 5.49277 45.8641C6.20902 46.5463 7.07721 46.8764 8.11903 46.8764H12.46C12.2863 46.4363 12.1995 45.9961 12.1995 45.512C12.1995 45.4459 12.1995 45.3799 12.1995 45.3359C12.2429 44.5436 12.46 43.8174 12.8723 43.1792H8.09733V32.1533L8.11903 32.1753Z"
            fill="white"
          />
          <path d="M27.3489 43.2009H19.6221L17.4082 45.4237L18.8624 46.8982H27.3489V43.2009Z" fill="white" />
          <path
            d="M16.5384 45.4239L27.4993 34.31V29.1162L13.9773 42.827C13.8688 42.9371 13.7602 43.0691 13.6517 43.1791C13.1308 43.8174 12.8486 44.5656 12.8269 45.3799C12.8052 45.9081 12.9138 46.4143 13.1308 46.8985C13.2393 47.1185 13.3479 47.3166 13.4998 47.5147C13.63 47.6907 13.7819 47.8668 13.9556 48.0429L21.639 55.8336C22.3987 56.6039 23.2669 57 24.2436 57C25.2203 56.978 26.0668 56.5819 26.8047 55.8336L29.8651 52.7305C29.4527 52.5324 29.062 52.2903 28.7364 51.9382C28.693 51.8942 28.6496 51.8502 28.6062 51.8062C28.0853 51.234 27.738 50.5517 27.5861 49.7815L24.2002 53.2147L16.5167 45.4239H16.5384Z"
            fill="white"
          />
          <path d="M37.9158 39.3496L32.3594 44.9836V48.1527H34.3996L40.4986 41.9685L37.9158 39.3496Z" fill="white" />
          <path d="M28.7385 29.1162H33.8608L42 37.3691V42.5849L28.7385 29.1162Z" fill="white" />
          <path d="M38.7874 18.5524L44.3654 24.2084H47.4909V22.1176L41.3702 15.9335L38.7874 18.5524Z" fill="white" />
          <path
            d="M55.0889 29.8199L52.0285 26.7168C51.8332 27.1349 51.5944 27.5311 51.2472 27.8612C51.2038 27.9052 51.1603 27.9492 51.1169 27.9932C50.5526 28.5214 49.8798 28.8736 49.1201 29.0276L52.506 32.4608L46.9062 38.1388V43.3106C47.0799 43.1786 47.2535 43.0465 47.4055 42.8705L55.0889 35.0797C55.8485 34.3095 56.2392 33.4292 56.2392 32.4388C56.2175 31.4485 55.8268 30.5902 55.0889 29.8419V29.8199Z"
            fill="white"
          />
        </svg>

        <div class="accordion-item">
          <button class="accordion-header collapsed" (click)="toggle(0)">
            <span>Sobre mi plan</span>
            <span class="icon_container">
              <i-feather
                [ngClass]="{ collapsed: !isCollapsed[0] }"
                class="icon"
                name="chevron-up"
                _nghost-yeo-c4=""
                ng-reflect-name="chevron-up"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-chevron-up">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                  ></path>
                </svg>
              </i-feather>
            </span>
          </button>
          <div [ngClass]="{ collapse: !isCollapsed[0] }" class="accordion-content">
            <a class="txt_link d-block pl-2" href="#!">Coberturas</a>
            <a class="txt_link d-block pl-2" href="#!">Mis Dependientes</a>
            <a class="txt_link d-block pl-2" href="#!">Contrato</a>
            <a class="txt_link d-block pl-2" href="#!">Otros productos</a>
          </div>
        </div>

        <div class="accordion-item">
          <button class="accordion-header collapsed" (click)="toggle(1)">
            <span>Facturación</span>
            <span class="icon_container">
              <i-feather
                [ngClass]="{ collapsed: !isCollapsed[1] }"
                class="icon"
                name="chevron-up"
                _nghost-yeo-c4=""
                ng-reflect-name="chevron-up"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-chevron-up">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                  ></path>
                </svg>
              </i-feather>
            </span>
          </button>
          <div [ngClass]="{ collapse: !isCollapsed[1] }" class="accordion-content">
            <a class="txt_link d-block pl-2" href="#!">Facturas de Servicios</a>
            <a class="txt_link d-block pl-2" href="#!">Recibos de Pago</a>
          </div>
        </div>

        <div class="accordion-item">
          <button class="accordion-header collapsed" (click)="toggle(2)">
            <span>Documentos</span>
            <span class="icon_container">
              <i-feather
                [ngClass]="{ collapsed: !isCollapsed[2] }"
                class="icon"
                name="chevron-up"
                _nghost-yeo-c4=""
                ng-reflect-name="chevron-up"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-chevron-up">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                  ></path>
                </svg>
              </i-feather>
            </span>
          </button>
          <div [ngClass]="{ collapse: !isCollapsed[2] }" class="accordion-content">
            <a class="txt_link d-block pl-2" href="#!">Condiciones Particulares</a>
            <a class="txt_link d-block pl-2" href="#!">Superintendencia de Seguros(SIS)</a>
          </div>
        </div>

        <div class="accordion-item">
          <button class="accordion-header collapsed" (click)="toggle(3)">
            <span>Contáctanos</span>
            <span class="icon_container">
              <i-feather
                [ngClass]="{ collapsed: !isCollapsed[3] }"
                class="icon"
                name="chevron-up"
                _nghost-yeo-c4=""
                ng-reflect-name="chevron-up"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-chevron-up">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                  ></path>
                </svg>
              </i-feather>
            </span>
          </button>
          <div [ngClass]="{ collapse: !isCollapsed[3] }" class="accordion-content">
            <div class="p-2">
              <p class="txt_link">
                <i-feather class="mr-1" name="mail" _nghost-yeo-c4="" ng-reflect-name="mail">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-mail">
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    ></path>
                  </svg>
                </i-feather>
                clientes&#64;universal.com.do
              </p>
              <p class="txt_link">
                <i-feather class="mr-1" name="map-pin" _nghost-yeo-c4="" ng-reflect-name="map-pin">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-map-pin">
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    ></path>
                  </svg>
                </i-feather>
                OFICINA CORPORATIVA
                <span class="d-block mx-auto" style="font-weight: 300; line-height: 16.94px">
                  Av. Winston Churchill 1100, Evaristo Morales, Santo Domingo, D.N.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="copyright_and_redsocial row pt-4 pb-4 px-2 px-lg-5 mx-0">
    <div class="col-12 col-lg-5 order-lg-last text-left text-lg-right mb-3 mb-lg-0">
      <!-- ICON FACEBOOK -->
      <a class="red_social_link" (click)="goToHere(links['FACEBOOK'])">
        <svg class="mr-4" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.6322 10.4215H10.4215V6.2529C10.4215 5.10236 11.3553 5.21075 12.5058 5.21075H14.5901V0H10.4215C6.96781 0 4.1686 2.79921 4.1686 6.2529V10.4215H0V15.6322H4.1686V27.0959H10.4215V15.6322H13.5479L15.6322 10.4215Z"
            fill="white"
          />
        </svg>
      </a>

      <!-- ICON INSTAGRAM -->
      <a class="red_social_link" (click)="goToHere(links['INSTAGRAM'])">
        <svg class="mr-4" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.3088 0.01323C8.69169 0.01323 3.35766 -0.486448 1.32027 4.74239C0.478705 6.90199 0.600918 9.70669 0.600918 13.7227C0.600918 17.2467 0.487987 20.5588 1.32027 22.7014C3.35302 27.9333 8.73036 27.4321 14.3057 27.4321C19.6846 27.4321 25.2306 27.9921 27.2927 22.7014C28.1358 20.5202 28.0121 17.7572 28.0121 13.7227C28.0121 8.36699 28.3076 4.90946 25.7101 2.31361C23.0803 -0.316279 19.5237 0.01323 14.3026 0.01323H14.3088ZM13.0805 2.48378C24.7974 2.46521 26.2887 1.16265 25.4657 19.2578C25.1733 25.6577 20.3003 24.9554 14.3104 24.9554C3.3886 24.9554 3.07456 24.6429 3.07456 13.7165C3.07456 2.66323 3.94087 2.48997 13.0805 2.48068V2.48378ZM21.6261 4.7594C20.718 4.7594 19.9816 5.49577 19.9816 6.40386C19.9816 7.31194 20.718 8.04831 21.6261 8.04831C22.5342 8.04831 23.2705 7.31194 23.2705 6.40386C23.2705 5.49577 22.5342 4.7594 21.6261 4.7594ZM14.3088 6.68231C10.4212 6.68231 7.27 9.83509 7.27 13.7227C7.27 17.6103 10.4212 20.7615 14.3088 20.7615C18.1964 20.7615 21.3461 17.6103 21.3461 13.7227C21.3461 9.83509 18.1964 6.68231 14.3088 6.68231ZM14.3088 9.15286C20.3498 9.15286 20.3576 18.2925 14.3088 18.2925C8.26936 18.2925 8.26008 9.15286 14.3088 9.15286Z"
            fill="white"
          />
        </svg>
      </a>

      <!-- ICON LINKEDIN -->
      <a class="red_social_link" (click)="goToHere(links['LINKEDIN'])">
        <svg class="mr-4" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.23643 3.47515C7.23643 4.13002 7.04224 4.77019 6.67842 5.31469C6.31459 5.8592 5.79747 6.28359 5.19245 6.5342C4.58742 6.7848 3.92167 6.85038 3.27939 6.72262C2.6371 6.59486 2.04712 6.27951 1.58405 5.81644C1.12099 5.35338 0.80564 4.7634 0.677881 4.12111C0.550122 3.47882 0.615692 2.81307 0.8663 2.20805C1.11691 1.60303 1.5413 1.08591 2.08581 0.722081C2.63031 0.358254 3.27048 0.164062 3.92535 0.164062C4.8035 0.164062 5.64569 0.512908 6.26664 1.13386C6.88759 1.75481 7.23643 2.597 7.23643 3.47515Z"
            fill="white"
          />
          <path
            d="M6.29066 8.54591V23.0031C6.2912 23.1097 6.27206 23.2154 6.23436 23.3141C6.19665 23.4128 6.14111 23.5026 6.07094 23.5782C6.00077 23.6538 5.91734 23.7138 5.82546 23.7548C5.73358 23.7957 5.63506 23.8168 5.53555 23.8168H2.31769C2.21819 23.8171 2.11961 23.7963 2.02763 23.7556C1.93565 23.7149 1.85207 23.6552 1.78171 23.5797C1.71135 23.5043 1.65559 23.4148 1.61764 23.3162C1.57968 23.2176 1.56028 23.1119 1.56055 23.0053V8.54591C1.56055 8.33068 1.64032 8.12426 1.78231 7.97207C1.9243 7.81988 2.11689 7.73438 2.31769 7.73438H5.53555C5.73601 7.73495 5.92807 7.82071 6.06962 7.97284C6.21117 8.12497 6.29066 8.33106 6.29066 8.54591Z"
            fill="white"
          />
          <path
            d="M24.2641 16.1861V23.0817C24.2644 23.1783 24.2448 23.274 24.2066 23.3633C24.1685 23.4526 24.1123 23.5338 24.0415 23.6021C23.9707 23.6704 23.8865 23.7246 23.7939 23.7614C23.7013 23.7983 23.6021 23.8171 23.5019 23.8168H19.7137C19.6135 23.8171 19.5143 23.7983 19.4217 23.7614C19.3291 23.7246 19.2449 23.6704 19.1741 23.6021C19.1033 23.5338 19.0471 23.4526 19.009 23.3633C18.9708 23.274 18.9512 23.1783 18.9515 23.0817V16.3989C18.9515 15.4016 19.2546 12.0312 16.2485 12.0312C13.9198 12.0312 13.4451 14.3376 13.3515 15.3736V23.0817C13.3516 23.2748 13.2728 23.4602 13.1323 23.5977C12.9918 23.7353 12.8008 23.814 12.6006 23.8168H8.94153C8.84155 23.8168 8.74256 23.7978 8.65022 23.7608C8.55788 23.7238 8.47401 23.6696 8.40342 23.6014C8.33283 23.5331 8.2769 23.452 8.23885 23.3628C8.20079 23.2737 8.18135 23.1781 8.18164 23.0817V8.70809C8.18135 8.61165 8.20079 8.51611 8.23885 8.42693C8.2769 8.33776 8.33283 8.2567 8.40342 8.18841C8.47401 8.12012 8.55788 8.06594 8.65022 8.02897C8.74256 7.992 8.84155 7.97297 8.94153 7.97297H12.6006C12.8027 7.97297 12.9965 8.05042 13.1395 8.18828C13.2824 8.32614 13.3627 8.51312 13.3627 8.70809V9.95049C14.2273 8.69949 15.5086 7.73438 18.2429 7.73438C24.2997 7.73438 24.2641 13.1898 24.2641 16.1861Z"
            fill="white"
          />
        </svg>
      </a>

      <!-- ICON YOUTUBE -->
      <a class="red_social_link" (click)="goToHere(links['YOUTUBE'])">
        <svg class="mr-4" width="33" height="23" viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.1296 6.85465C31.9045 3.90224 31.3393 0.853604 27.3743 0.630534C19.9449 0.227322 12.4975 0.227322 5.06808 0.630534C1.10304 0.84923 0.537919 3.90224 0.31279 6.85465C0.0819347 9.78982 0.0819347 12.7373 0.31279 15.6725C0.537919 18.6249 1.10304 21.6735 5.06808 21.8966C12.4975 22.2998 19.9449 22.2998 27.3743 21.8966C31.3393 21.6779 31.9045 18.6249 32.1296 15.6725C32.3604 12.7373 32.3604 9.78982 32.1296 6.85465ZM13.0073 15.2001V6.45224L21.7369 10.8262L13.0073 15.2001Z"
            fill="white"
          />
        </svg>
      </a>
      <!-- ICON X -->
      <a class="red_social_link" (click)="goToHere(links['X'])">
        <svg class="mr-4" width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.0477 10.8934L25.6237 0H23.3582L15.0516 9.4474L8.39985 0H0.751953L10.7938 14.2996L0.751953 25.7072H3.0174L11.79 15.7296L18.7952 25.7072H26.4431L16.0317 10.8934H16.0477ZM12.9468 14.4121L11.9346 12.9821L3.83682 1.67097H7.32336L13.8626 10.8131L14.8749 12.2431L23.3582 24.1166H19.8717L12.9468 14.4121Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
    <div class="col-12 col-lg-7 order-lg-first">
      <span class="txt_copyright"
        ><span class="d-none d-lg-inline">Seguros Universal</span> © {{ currentYear }} Copyright.Todos los derechos
        reservados.</span
      >
    </div>
  </div>
  <!-- Copyright -->
</footer>
