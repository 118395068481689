<section
  class="warning"
>
  <div class="modal-x">
    <h4 uni-heading class="modal-title"><span class="text-color-warning">{{ modal.title }}</span></h4>
  </div>
  <div class="modal-body" [innerHTML]="modal.trustedHtml"></div>

  <div class="local-footer">
    <button class="btn-no" (click)="cancel()">NO</button>
    <button class="btn-yes" (click)="ok()">SI</button>
  </div>
</section>
