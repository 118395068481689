import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ParamsService {
  public mapRequestParams(request: Record<string, any> = {}, paramsRenamed?: Record<string, string>): HttpParams {
    if (paramsRenamed) {
      Object.keys(paramsRenamed).forEach((key) => {
        const newName = paramsRenamed[key];
        request[newName] = request[key];
        delete request[key];
      });
    }

    let params = new HttpParams();
    if (!request) return params;

    Object.keys(request).forEach((key) => {
      const value = request[key];
      if (value instanceof Date) {
        params = params.append(key, value.toJSON());
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          params = params.append(`${key}`, item);
        });
      } else if (value !== null && value !== undefined && value !== "" && value !== "null") {
        params = params.append(key, value.toString().trim());
      }
    });

    return params;
  }

  public cleanCodigoProducto(request: Record<string, any>): Record<string, any> {
    //TODO: ADD THIS TO THE AFFILIATE REPOSITORY AND DELETE THIS MAGIC STRING
    if (request.codigoProducto === "00") {
      delete request.codigoProducto;
    }
    return request;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public trimResponseObject(response: Object): any {
    const cleanResponse = {};
    Object.keys(response).forEach((key) => {
      cleanResponse[key] = typeof response[key] === "string" ? response[key].trim() : response[key];
    });
    return cleanResponse;
  }

  public createFormData(request: Record<string, any>): FormData {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => formData.append(key, value));
    return formData;
  }
}
