import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer-onboarding",
  templateUrl: "./footer-onboarding.component.html",
  styleUrls: ["./footer-onboarding.component.scss"],
})
export class FooterOnboardingComponent implements OnInit {
  protected currentYear: number;
  protected isCollapsed = [false, false, false, false];

  public links: { [key: string]: string } = {
    FACEBOOK: "https://www.facebook.com/UniversalRD",
    INSTAGRAM: "https://www.instagram.com/universal_rd",
    LINKEDIN: "https://www.linkedin.com/company/grupouniversal",
    YOUTUBE: "https://www.youtube.com/@universalrd",
    X: "https://x.com/universalrd",
  };

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
  public toggle(index: number): void {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }

  public goToHere(url: string): void {
    window.open(url, "_blank");
  }
}
