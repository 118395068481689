import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, of } from "rxjs";
import { first } from "rxjs/operators";

import { ModalTitles, ModalType } from "@shared/Constantes/modal.constantes";

import { ConfirmGeneralComponent } from "../../app/General/Confirm/confirm.component";
import { ConfirmGeneral } from "../../app/General/Confirm2/confirm.component";
import { ModalGeneralComponent } from "../../app/General/Modal/modal.component";
import { SuccessModalComponent } from "../../app/General/success-modal/success-modal.component";
import { Modal } from "../Entities/General/modal.entity";
import { ConfirmCustomGeneral } from "../../app/General/ConfirmCustom/confirm-custom.component";
import { ModalConfirm } from "@shared/Entities/General/modalConfirm.entity";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modalConfirmGeneral = null;
  constructor(private readonly modalService: BsModalService) {}

  public showModalGeneral(mensaje: Modal, staticBackdrop: boolean = false): Observable<boolean> {
    if (this.modalService.getModalsCount() < 1) {
      this.modalConfirmGeneral = this.modalService.show(ModalGeneralComponent, {
        class: mensaje.classes,
        initialState: {
          modal: mensaje,
        },
        backdrop: staticBackdrop ? "static" : true,
      });
      return this.modalConfirmGeneral.content.onClose;
    }
    return of(false);
  }

  public showModalGeneralAfilliations(mensaje: Modal, staticBackdrop: boolean = false): Observable<boolean> {
    this.modalConfirmGeneral = this.modalService.show(ModalGeneralComponent, {
      class: "modal-blur",
      ignoreBackdropClick: true,
      initialState: {
        modal: mensaje,
      },
      backdrop: staticBackdrop ? "static" : true,
    });

    setTimeout(() => {
      const modalContainer = document.querySelector("modal-container");
      if (modalContainer) {
        (modalContainer as HTMLElement)["webkitBackdropFilter"] = "blur(4px)";
      }
    }, 0);

    return this.modalConfirmGeneral.content.onClose;
  }

  public showSuccessModal(title: string, body: string, staticBackdrop: boolean = false): Observable<boolean> {
    this.modalConfirmGeneral = this.modalService.show(SuccessModalComponent, {
      initialState: {
        title: title,
        body: body,
      },
      backdrop: staticBackdrop ? "static" : true,
    });
    return this.modalConfirmGeneral.content.onClose;
  }

  public showMiniSuccessModal(body: string): Observable<boolean> {
    return this.showModalGeneralAfilliations(
      {
        body,
        title: ModalTitles.success,
        type: ModalType.success,
      },
      true
    );
  }

  public showWarningModal(body: string): Observable<boolean> {
    return this.showModalGeneral({ body, title: ModalTitles.warning, type: ModalType.warning });
  }

  public showAfilliationsWarningModal(body: string): Observable<boolean> {
    return this.showModalGeneralAfilliations({ body, title: ModalTitles.warning, type: ModalType.warning });
  }

  public closeModalConfirm(): any {
    return this.modalConfirmGeneral.content.onClose;
  }

  public showConfirm(mensaje: Modal): Observable<boolean> {
    const modalConfirm = this.modalService.show(ConfirmGeneralComponent, {
      class: mensaje.classes,
      initialState: {
        modal: mensaje,
      },
    });
    return modalConfirm.content.onClose.pipe(first());
  }
  public showConfirmCustom(mensaje: ModalConfirm): Observable<boolean> {
    const modalConfirm = this.modalService.show(ConfirmCustomGeneral, {
      class: mensaje.classes,
      initialState: {
        modal: mensaje
      },
    });
    return modalConfirm.content.onClose.pipe(first());
  }
  public showConfirm2(mensaje: Modal): Observable<boolean> {
    const modalConfirm = this.modalService.show(ConfirmGeneral, {
      class: mensaje.classes,
      initialState: {
        modal: mensaje,
        // showDefaultMessage: showDefaultMessage
      },
    });
    return modalConfirm.content.onClose.pipe(first());
  }

  public getIcon(type: string): string {
    if (type === "danger") return "bi-x";
    if (type === "warning") return "bi-exclamation";
    if (type === "success") return "bi-check";
    if (type === "info") return "bi-info";
  }
}
