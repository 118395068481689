import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirm } from '../../../shared/Entities/General/modalConfirm.entity';
import { ModalService } from '@shared/Services/modal.service';

@Component({
  templateUrl: './confirm-custom.component.html',
  styleUrls: ['./confirm-custom.component.scss']
})
export class ConfirmCustomGeneral  implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  modal: ModalConfirm;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef, public modalService: ModalService, public bsModalService: BsModalService) {

  }

  ngOnInit() {
      this.onClose = new Subject();

      if (!this.modal)
          this.modal = new ModalConfirm();

      this.modal.trustedHtml = this.modal.body;
      this.modal.useConfirmMessage = this.modal.useConfirmMessage ?? true;
      this.modal.confirmMessage =  this.modal.confirmMessage ?? "¿Deseas continuar?";
      this.modal.yesButtonMessage =  this.modal.yesButtonMessage ?? "SI";
      this.modal.noButtonMessage = this.modal.noButtonMessage ?? "NO";
  }

  ok() {
      this.onClose.next(true);
      this.bsModalRef.hide();
  }
  cancel() {
      this.onClose.next(false);
      this.bsModalRef.hide();
  }

  ngOnDestroy() {
      this.subscriptions.forEach(subscription => {
          if (!subscription.closed)
              subscription.unsubscribe();
      });
  }

  public getIcon(type: string): string {
    return this.modalService.getIcon(type);
  }
}
