import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { format } from "date-fns";
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";

import { ReferringDoctor } from "@salud/Autorizaciones/Formulario/Types/referring-doctor.enum";

import {
  AutorizacionResponse,
  ConsultaAutorizacionesEnCurso,
} from "../../app/SaludLocal/ConfinementAdmission/Interfaces/confinement-admission";
import { Afiliado } from "../Entities/Autorizaciones/afiliado.entity";
import { AnulacionAutorizacion } from "../Entities/Autorizaciones/anulacion.entity";
import { Autorizacion } from "../Entities/Autorizaciones/autorizacion.entity";
import { ConsultaAfiliado } from "../Entities/Autorizaciones/consultaAfiliado.entity";
import { DiagnosticoARS } from "../Entities/Autorizaciones/diagnosticoARS.entity";
import { MedicoARS } from "../Entities/Autorizaciones/medicoARS.entity";
import { OrigenARS } from "../Entities/Autorizaciones/origenARS.entity";
import { PrestacionARS } from "../Entities/Autorizaciones/prestacionARS.entity";
import { Procedimiento } from "../Entities/Autorizaciones/procedimientos.entity";
import { RazonAnulacion } from "../Entities/Autorizaciones/razon.entity";
import { ServicioARS } from "../Entities/Autorizaciones/servicioARS.entity";
import { Usuario } from "../Entities/Usuario/usuario.entity";
import { AutorizacionesMapper } from "../Mappers/autorizaciones.mapper";
import { CaseService } from "../Services/case.service";
import { error } from "console";

const { enlineaBackendUrl, apiArsAutorizaciones, apiOdontograma } = environment;

@Injectable({
  providedIn: "root",
})
export class AutorizacionesRepository {
  public baseUrl = enlineaBackendUrl

  public servicios: ReplaySubject<ServicioARS[]>;
  public origenes: ReplaySubject<OrigenARS[]>;
  public medicosRemitentes: ReplaySubject<MedicoARS[]>;
  public medicosTratantes: ReplaySubject<MedicoARS[]>;
  public prestaciones: ReplaySubject<PrestacionARS[]>;

  public motivosAnulacion: ReplaySubject<RazonAnulacion[]>;
  private errorState = new BehaviorSubject<boolean>(false);

  private setErrorState(hasError: boolean) {
    this.errorState.next(hasError);
  }

  public getErrorState(): Observable<boolean> {
    return this.errorState.asObservable();
  }

  constructor(private http: HttpClient, private autorizacionesMapper: AutorizacionesMapper, private caseService: CaseService) {}

  //GET
  getAfiliado(term: string = ""): Observable<Afiliado[]> {
    if (term)
      // return this.http.get<Afiliado[]>(this.baseUrl + "/api/Afiliado?spinner=false&descripcion=" + term).pipe(
      return this.http.get<Afiliado[]>(this.baseUrl + "/api/ars/Autorizaciones/Afiliado?spinner=false&descripcion=" + term).pipe(
        map((resultados) => {
          return this.caseService.toCamelCase(resultados);
        })
      );
    else return of([]);
  }

  //Obtener autorizaciones en base a criterios de busqueda
  obtenerAutorizaciones(filters): Observable<Autorizacion[]> {
    for (const property in filters) {
      if (!filters[property]) {
        delete filters[property];
      }
    }

    // return this.http.get<Autorizacion[]>(`${this.baseUrl}/api/Autorizacion`, { params: filters });
    return this.http.get<Autorizacion[]>(`${this.baseUrl}/api/ars/Autorizaciones/Autorizacion`, { params: filters });
  }

  //Obtener autorizaciones en curso en base a numero de afiliado
  public obtenerAutorizacionesEnCurso(numeroAfiliado: number): Observable<ConsultaAutorizacionesEnCurso> {
    return this.http.get<ConsultaAutorizacionesEnCurso>(
      `${this.baseUrl}/api/ars/Autorizaciones/ConsultaAutorizacionesEnCurso?NumeroAfiliado=${numeroAfiliado}`
    );
  }

   //Obtener autorizaciones en curso en base a numero de afiliado y codigo prestador
   public obtenerAutorizacionesEnCursoPorAfiliadoYPrestador(numeroAfiliado: number, codigoPrestador: number): Observable<ConsultaAutorizacionesEnCurso> {
    return this.http.get<ConsultaAutorizacionesEnCurso>(
      `${this.baseUrl}/api/ars/Autorizaciones/ConsultaAutorizacionesEnCurso?NumeroAfiliado=${numeroAfiliado}&CodigoPrestador=${codigoPrestador}`
    );
  }

  //Enviar notificacion mediante la modificacion autorizacion en curso

  public notificarAutorizacionEnCurso(
    codigoUsuario: string,
    numeroAutorizacion: number,
    codigoDiagnostico: string,
    codigoTipoAtencion: number,
    codigoServicio: string
  ): Observable<AutorizacionResponse> {
    const url = `${this.baseUrl}/api/ars/Autorizaciones/ModificarAutorizacionAInternamiento`;
    const params = new HttpParams()
      .set("codigoUsuario", codigoUsuario)
      .set("numeroAutorizacion", numeroAutorizacion)
      .set("codigoDiagnostico", codigoDiagnostico)
      .set("codigoTipoAtencion", codigoTipoAtencion)
      .set("codigoServicio", codigoServicio);

    return this.http.post<AutorizacionResponse>(url, {}, { params: params }).pipe(
      catchError((error) => {
        this.setErrorState(true);
        return throwError(() => new Error(error));
      })
    );
  }

  //Obtener Razon de anulacion autorizaciones
  historialAutorizacionesEstado(id): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/ars/Autorizaciones/${id}/HistoricoCambioEstado`);
  }

  obtenerProcedimientosAutorizaciones(filter): Observable<Procedimiento[]> {
    return this.http.get<Procedimiento[]>(
      `${this.baseUrl}/api/ars/Autorizaciones/ProcedimientosAutorizados?codigoUsuario=${filter.codigoUsuario}&numeroAutorizacion=${filter.numeroAutorizacion}`
    );
  }

  /* FORM DROPDOWNS */
  public getServicios(codigoPrestador: string, overwrite: boolean = false): Observable<ServicioARS[]> {
    if (!this.servicios || overwrite) {
      if (!this.servicios) this.servicios = new ReplaySubject(1);

      this.http
        .get<ServicioARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/Servicios?codigoPrestadorSalud=${codigoPrestador}`)
        .subscribe({
          next: (results) => {
            this.servicios.next(this.caseService.toCamelCase(results));
          },
          error: (error) => {
            this.setErrorState(true);
            return throwError(() => new Error(error));
          },
        });
    }

    return this.servicios;
  }

  public getServiciosInternamiento(): Observable<ServicioARS[]> {
    if (!this.servicios) {
      this.servicios = new ReplaySubject(1);
    }
    this.http.get<ServicioARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/ServiciosInternamiento`).subscribe({
      next: (results) => {
        this.servicios.next(this.caseService.toCamelCase(results));
      },
      error: (error) => {
        this.setErrorState(true);
        return throwError(() => new Error(error));
      },
    });
    return this.servicios;
  }

  getOrigenes(overwrite: boolean = false): Observable<OrigenARS[]> {
    if (!this.origenes || overwrite) {
      if (!this.origenes) this.origenes = new ReplaySubject(1);

      this.http.get<OrigenARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/TiposOrigenes?spinner=false`).subscribe((results) => {
        this.origenes.next(this.caseService.toCamelCase(results));
      });
    }

    return this.origenes;
  }
  getMedicosRemitentes(term: string = ""): Observable<MedicoARS[]> {
    if (term)
      return this.http.get<MedicoARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/Remitentes?spinner=false&descripcion=${term}`).pipe(
        map((medicos) => {
          return this.caseService.toCamelCase(medicos);
        })
      );
    else return of([]);
  }
  getMedicosTratantes(term: string = ""): Observable<MedicoARS[]> {
    if (term)
      return this.http.get<MedicoARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/Doctores?spinner=false&descripcion=${term}`).pipe(
        map((medicos) => {
          return this.caseService.toCamelCase(medicos);
        })
      );
    else return of([]);
  }
  public getDiagnosticos(term: string = ""): Observable<DiagnosticoARS[]> {
    if (term)
      return this.http.get<DiagnosticoARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/Diagnosticos?spinner=false&descripcion=${term}`).pipe(
        map((diagnosticos) => {
          return this.caseService.toCamelCase(diagnosticos);
        }),
        catchError((error) => {
          this.setErrorState(true);
          return throwError(() => new Error(error));
        })
      );
    else return of([]);
  }
  getPrestaciones(term: string = ""): Observable<PrestacionARS[]> {
    if (term)
      return this.http
        .get<PrestacionARS[]>(`${this.baseUrl}/api/ars/Autorizaciones/Procedimientos?spinner=false&descripcion=${term}`)
        .pipe(
          map((prestaciones) => {
            return this.caseService.toCamelCase(prestaciones);
          })
        );
    else return of([]);
  }

  getCamposRequeridos(codigoUsuario, idInteraccion): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/api/ars/Autorizaciones/CamposRequeridos?codigoUsuario=${codigoUsuario}&idInteraccion=${idInteraccion}`
    );
  }

  getMotivosAnulacion(overwrite: boolean = false): Observable<RazonAnulacion[]> {
    if (!this.motivosAnulacion || overwrite) {
      if (!this.motivosAnulacion) this.motivosAnulacion = new ReplaySubject(1);

      this.http
        .get<RazonAnulacion[]>(`${this.baseUrl}/api/ars/Autorizaciones/MotivosAnulacionAutorizacion?spinner=false`)
        .subscribe((results) => {
          this.motivosAnulacion.next(this.caseService.toCamelCase(results));
        });
    }

    return this.motivosAnulacion;
  }

  validarCobertura(
    codigoUsuario,
    idInteraccion,
    codigoPrestadorSalud,
    codigoSucursal,
    numeroAfiliado,
    codigoServicio
  ): Observable<any> {
    return this.http.get(
      this.baseUrl +
        "/api/ars/Autorizaciones/ValidarCobertura" +
        "?codigoUsuario=" +
        codigoUsuario.trim() +
        (idInteraccion ? "&idInteraccion=" + idInteraccion : "") +
        "&codigoPrestadorSalud=" +
        codigoPrestadorSalud.trim() +
        (codigoSucursal ? "&codigoSucursalPrestadorSalud=" + codigoSucursal.trim() : "") +
        "&numeroAfiliado=" +
        numeroAfiliado +
        "&codigoServicio=" +
        codigoServicio.trim()
    );
  }
  getTarifaPrestacion(codigoUsuario, prestacion: PrestacionARS, autorizacion: Autorizacion): Observable<any> {
    return this.http.get(
      `${
        this.baseUrl
      }/api/ars/Autorizaciones/TarifaProcedimiento?spinner=false&codigoUsuario=${codigoUsuario.trim()}&idInteraccion=${
        autorizacion.idInteraccion
      }&codigoProcedimiento=${prestacion.codigo.trim()}`
    );
  }

  getPrestacionesAprobadas(çodigoUsuario, autorizacion): Observable<any> {
    return this.http
      .get(
        `${this.baseUrl}/api/ars/Autorizaciones/ProcedimientosAutorizados?codigoUsuario=${çodigoUsuario}&idInteraccion=${autorizacion.idInteraccion}`
      )
      .pipe(
        map((prestaciones) => {
          return this.caseService.toCamelCase(prestaciones);
        })
      );
  }
  getPrestacionesNegadas(codigoUsuario, autorizacion): Observable<any> {
    return this.http
      .get(
        `${this.baseUrl}/api/ars/Autorizaciones/ProcedimientosNegados?codigoUsuario=${codigoUsuario}&idInteraccion=${autorizacion.idInteraccion}`
      )
      .pipe(
        map((prestaciones) => {
          return this.caseService.toCamelCase(prestaciones);
        })
      );
  }

  //POST
  public crearCaso(currentUser: Usuario, autorizacion: Autorizacion): Observable<any> {
    autorizacion.fecha = autorizacion.fecha || new Date();
    return this.http.post(
      this.baseUrl +
        "/api/ars/Autorizaciones/Ingresar?" +
        "codigousuario=" +
        currentUser.identificacion.trim() +
        "&idInteraccion=" +
        autorizacion.idInteraccion +
        "&fecha=" +
        autorizacion.fecha.toJSON() +
        (autorizacion.medicoRemitente && autorizacion.medicoRemitente.Codigo
          ? "&codigoRemitente=" + autorizacion.medicoRemitente.Codigo.trim()
          : "") +
        (autorizacion.medicoTratante && autorizacion.medicoTratante.codigo
          ? "&idDoctor=" + autorizacion.medicoTratante.codigo.trim()
          : "") +
        (autorizacion.telefono ? "&telefono=" + autorizacion.telefono.trim() : "") +
        (autorizacion.correo ? "&email=" + autorizacion.correo : "") +
        (autorizacion.diagnostico && autorizacion.diagnostico.codigo
          ? "&codigoDiagnostico=" + autorizacion.diagnostico.codigo.trim()
          : ""),
      this.autorizacionesMapper.getAutorizacionAgregarDto(autorizacion)
    );
  }

  public startOdotograma(currentUser: Usuario, autorizacion: Autorizacion): Observable<any> {
    const body = {
      codigoUsuario: currentUser.identificacion.trim(),
      codigoPrestadorSalud: currentUser.metadata.codigoPrestador,
      idInteraccion: autorizacion.idInteraccion,
      sucursal: currentUser.companiaSucursal || "0",
      afiliado: autorizacion.afiliado.numero,
      servicio: autorizacion.servicio.codigo.trim(),
      telefono: autorizacion.telefono,
      email: autorizacion.correo,
      diagnostico: autorizacion.diagnostico.codigo.trim(),
      observacion: `Prescripción de Odontograma para ${autorizacion.afiliado.nombre}`,
    };

    return this.http.post(`${apiOdontograma}/odontograma/iniciar`, body);
  }

  crearAutorizacion(codigoUsuario, autorizacion: Autorizacion): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/ars/Autorizaciones/Autorizar?codigoUsuario=${codigoUsuario}&idInteraccion=${autorizacion.idInteraccion}`,
      this.caseService.toPascalCase(autorizacion.prestaciones)
    );
  }
  //PUT
  cancelarCaso(codigoUsuario, autorizacion: Autorizacion) {
    return this.http.post(
      `${this.baseUrl}/api/ars/Autorizaciones/Cancelar?codigoUsuario=${codigoUsuario}&idInteraccion=${autorizacion.idInteraccion}`,
      null
    );
  }
  anularAutorizacion(codigoUsuario, autorizacion: Autorizacion, anulacion: AnulacionAutorizacion) {
    return this.http.post(
      `${this.baseUrl}/api/ars/Autorizaciones/Anular?codigoUsuario=${codigoUsuario}&idInteraccion=${
        autorizacion.idInteraccion ? autorizacion.idInteraccion : autorizacion.IdInteraccion
      }&codigoMotivo=${anulacion.razon.codigo}&observacion=${anulacion.comentario}`,
      {}
    );
  }

  //EnlInea
  getAfiliadosCobertura(term): Observable<ConsultaAfiliado[]> {
    if (term)
      return this.http.get<ConsultaAfiliado[]>(
        `${enlineaBackendUrl}/api/Autorizaciones/ObtenerConsultaAfiliado?documento=${term} `
      );
    else return of([]);
  }

  descargarReporteAutorizaciones(codigoUsuario, codigoPrestador) {
    return this.http.get(
      `${enlineaBackendUrl}/api/Autorizaciones/ObtenerReporteTotalAutorizaciones/${codigoUsuario}/${codigoPrestador}/${format(
        new Date(),
        "yyyy-MM-dd"
      )}`,
      { responseType: "blob" }
    );
  }

  obtenerReporteARS(numeroAutorizacion, tipoReporte): Observable<any> {
    return this.http.get(
      `${enlineaBackendUrl}/api/Autorizaciones/ObtenerReporte/${numeroAutorizacion}?tipoReporte=${tipoReporte}`,
      { responseType: "blob" }
    );
  }

  public searchReferringDoctor(description: string): Observable<ReferringDoctor[]> {
    return this.http.get<ReferringDoctor[]>(
      `${this.baseUrl}/api/ars/Autorizaciones/Remitentes?spinner=false&descripcion=${description}`
    );
  }

  public encript(text: string | number): Observable<string> {
    return this.http
      .get<{ value: string }>(`${enlineaBackendUrl}/api/Autorizaciones/Encrypt?text=${text}`)
      .pipe(map((x) => x.value));
  }
}
