/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { differenceInDays, isValid, parse } from "date-fns";

import datatableMessages from "@assets/datatables/table-messages.json";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";

import { Action, Column, TableData, TableFilter } from "../types/table.interface";

@Component({
  selector: "uni-desktop-table",
  templateUrl: "./desktop-table.component.html",
  styleUrls: ["./desktop-table.component.scss"],
})
export class DesktopTableComponent implements OnChanges {
  public offset = 0;
  public maxPage = 0;

  @Input() public itemsCount: number;
  @Input() public currentPage = 1;
  @Output() public currentPageChange = new EventEmitter<number>();

  @Input() public pageSize = 10;
  @Output() public pageSizeChange = new EventEmitter<number>();

  public allSelected: boolean = true;
  public allRowsSelected: boolean = false;
  public isUnitPortalStyle: boolean = false;

  //ALL COLUMNS
  @Input() public actions: Action[] | Action[][];
  @Input() public columns: Column[];
  @Input() public originalColumns: Column[] = [];

  //INPUT DATA
  @Input() public data: TableData[];
  public displayData = [];

  @Input() public set loading(loading: boolean) {
    this.datatableMessages = {
      ...datatableMessages,
      emptyMessage: loading ? datatableMessages.loadingMessage : datatableMessages.emptyMessage,
    };
  }

  //ITEM COUNT
  public itemCount = 0;

  //SELECTED DATA
  @Input() public selectedData = [];

  //EXCEL
  @Input() public excelName: string;
  @Input() public customExport;
  @Output() public exportExcel = new EventEmitter();

  //ROW CLICK
  @Input() public selectionType?: "multi" | "single" = "single";
  @Input() public selectColumnName: string = "Seleccionar";
  @Output() public rowClicked = new EventEmitter();
  @Output() public selectRow = new EventEmitter<any>();

  @ViewChild(DatatableComponent, { static: true }) private table: DatatableComponent;
  public datatableMessages = { ...datatableMessages };

  //TEMPLATES
  @ViewChild("yesNoTemplate", { static: true }) public yesNoTemplate: TemplateRef<any>;

  @Output() public filter = new EventEmitter<void | TableFilter>();

  public shownPageNumber = 3;
  public pageCountArray = [];
  public pageDisplayLimitArray = [{ value: 5 }, { value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.setData(this.data);
    }
  }

  constructor(private unitSharedService: UnitSharedService) {
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }
  //DATA CONTROLS
  public setData(data: TableData[]): void {
    if (data) {
      this.setMaxPage();
      this.setPageCountArray();
      this.setDisplayData();
      this.setPage(1);
    }
  }

  public searchDatatable(): void {
    this.setMaxPage();
    this.setPageCountArray();
    this.setPage(1);
  }

  //SORTING
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public customSort(event): void {
    const dateFormat = "dd/MM/yyyy";
    const sortDirection = event.sorts[0].dir;
    const sortColumn = this.columns.find((p) => p.name.toUpperCase() === event.column.name.toUpperCase()).prop;
    this.data.sort((a, b) => this.compareValues(a[sortColumn] as string, b[sortColumn] as string, dateFormat, sortDirection));

    this.setDisplayData();
  }

  private compareValues(firstValue: string, secondValue: string, dateFormat: string, sortDirection: string): number {
    const firstDate = parse(firstValue, dateFormat, new Date());
    const secondDate = parse(secondValue, dateFormat, new Date());

    const isFirstDateValid = isValid(firstDate);
    const isSecondDateValid = isValid(secondDate);

    if (isFirstDateValid || isSecondDateValid) {
      return this.compareDates(firstDate, secondDate, isFirstDateValid, isSecondDateValid, sortDirection);
    }

    const firstNum = parseFloat(firstValue);
    const secondNum = parseFloat(secondValue);

    if (!isNaN(firstNum) && !isNaN(secondNum)) {
      return this.compareNumbers(firstNum, secondNum, sortDirection);
    }

    return this.compareStrings(firstValue, secondValue, sortDirection);
  }

  private compareDates(
    firstDate: Date,
    secondDate: Date,
    isFirstDateValid: boolean,
    isSecondDateValid: boolean,
    sortDirection: string
  ): number {
    if (isFirstDateValid && isSecondDateValid) {
      const daysDifference = differenceInDays(firstDate, secondDate);
      return sortDirection === "asc" ? daysDifference : -daysDifference;
    }

    if (isFirstDateValid && !isSecondDateValid) {
      return sortDirection === "asc" ? -1 : 1;
    }

    if (!isFirstDateValid && isSecondDateValid) {
      return sortDirection === "asc" ? 1 : -1;
    }

    return 0; // both dates are invalid, shouldn't reach here as handled in compareValues
  }

  private compareNumbers(firstNum: number, secondNum: number, sortDirection: string): number {
    return sortDirection === "asc" ? firstNum - secondNum : secondNum - firstNum;
  }

  private compareStrings(firstValue: string, secondValue: string, sortDirection: string): number {
    return sortDirection === "asc" ? firstValue.localeCompare(secondValue) : secondValue.localeCompare(firstValue);
  }

  public searchData(searchText: string): void {
    this.filter.emit({ searchText });
  }

  public setSelectedColumns(): void {
    this.columns = this.originalColumns.filter((column) => (column as any).selected);
    this.allSelected = this.originalColumns.length === this.columns.length;
    this.filter.emit({ columns: this.columns });
  }

  //ROW EVENTS
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public activate(event: any): void {
    if (this.selectionType && event.type === "click") {
      this.onRowClick(event.row);
    }
  }

  public onExportExcel(): void {
    this.exportExcel.emit();
  }

  private onRowClick(row) {
    this.rowClicked.emit(row);
  }

  public toggleSelectAllRow(): void {
    if (this.allRowsSelected) {
      this.table.selected = [];
    } else {
      this.table.selected = [...this.data];
    }
    this.allRowsSelected = !this.allRowsSelected;
    this.onSelect(this.table.selected);
  }

  public onSelect(selectedRows: TableData[]): void {
    this.selectRow.emit(selectedRows);
  }

  //RESPONSIVE MODAL CONTROLS
  public showFilterModal(): void {
    this.filter.emit();
  }

  //PAGE CONTROLS
  public setMaxPage(): void {
    this.maxPage = Math.ceil(this.data.length / this.pageSize);
  }

  public setPageCountArray(): void {
    if (this.pageCountArray.length > this.maxPage)
      for (let i = this.pageCountArray.length; i > this.maxPage; i--) this.pageCountArray.pop();
    else for (let i = this.pageCountArray.length; i < this.maxPage; i++) this.pageCountArray.push({ value: i + 1 });
  }

  public setPageSize(newPageSize: string): void {
    this.pageSize = parseInt(newPageSize, 10);
    this.setMaxPage();

    this.setPageCountArray();

    if (this.currentPage > this.maxPage) this.currentPage = this.maxPage;
    this.table.limit = this.pageSize;

    this.setDisplayData();
    this.setPage(1);
    this.pageSizeChange.emit(this.pageSize);
  }

  public setPage(newPage: string | number): void {
    this.currentPage = parseInt(newPage as string, 10);
    this.offset = this.pageSize * (this.currentPage - 1);
    this.setDisplayData();
    this.currentPageChange.emit(this.currentPage);
  }

  private setDisplayData() {
    this.itemCount = this.data.length;
    this.displayData = this.data.slice(this.offset, this.offset + this.pageSize);
    this.displayData = this.displayData.map((el) => el);
  }

  public singleSelectCheck(row: unknown): boolean {
    return (this as any).selected.indexOf(row) === -1;
  }

  public toggleAllSelected(): void {
    this.allSelected = !this.allSelected;
    this.originalColumns.forEach((column) => {
      column.selected = this.allSelected;
    });
    this.setSelectedColumns();
  }
}
