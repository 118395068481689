<section
  [class.success-modal]="modal.type === 'success'"
  [class.warning-modal]="modal.type === 'warning'"
  [class.danger-modal]="modal.type === 'danger'"
  [class.info-modal]="modal.type === 'info'"
>
  <div class="modal-header">
    <div class="modal-icon">
      <uni-icon size="8xl" weight="bold" color="white" [name]="getIcon(modal.type)"></uni-icon>
    </div>

    <h4 uni-heading [color]="modal.type" alignText="center" class="modal-title mt-3">{{ modal.title }}</h4>
  </div>
  <div class="modal-body" [innerHTML]="modal.trustedHtml"></div>

  <div class="modal-footer">
    <p *ngIf=modal.useConfirmMessage>{{modal.confirmMessage}}</p>

    <button uni-button fill="outline" variant="warning" class="btn-small-outline-confirm" style="padding: 0.575rem 1.75rem;" (click)="cancel()">{{modal.noButtonMessage}}</button>
    <button uni-button class="btn-small-confirm" (click)="ok()">{{modal.yesButtonMessage}}</button>
  </div>
</section>
